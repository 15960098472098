import runAfterPageLoad from "./runAfterPageLoad";

export function modernDetailLink(clickSelector) {
  runAfterPageLoad(() => {
    $(clickSelector).on("keypress", gotoProductDetails);
    $(clickSelector).on("keydown", gotoProductDetails);
  });
}

function gotoProductDetails(event) {
  if (event.which && event.which !== 1 && event.which !== 13) {
    return;
  }

  if (
    this.getAttribute("target") === "_blank" ||
    this.getAttribute("rel") === "noopener"
  ) {
    window.open(
      this.getAttribute("href"),
      this.getAttribute("target"),
      this.getAttribute("rel")
    );
  } else {
    window.location.href = this.getAttribute("href");
  }
}

export function modernBookmark(
  clickSelector,
  bookmarkedIconSelector,
  notBookmarkedIconSelector,
  inactiveClass
) {
  runAfterPageLoad(() => {
    if (sessionStorage.getItem("current-user-token")) {
      $(clickSelector).on(
        "click",
        toggleBookmarkFunc(
          clickSelector,
          bookmarkedIconSelector,
          notBookmarkedIconSelector,
          inactiveClass
        )
      );

      $(clickSelector).on(
        "keypress",
        toggleBookmarkFunc(
          clickSelector,
          bookmarkedIconSelector,
          notBookmarkedIconSelector,
          inactiveClass
        )
      );
    }
  });
}

function toggleBookmarkFunc(
  clickSelector,
  bookmarkedIconSelector,
  notBookmarkedIconSelector,
  inactiveClass
) {
  return async function toggleBookmark(event) {
    if (event.which && event.which !== 1 && event.which !== 13) {
      return;
    }

    const path = this.getAttribute("data-path");
    const isBookmarked = await callBookmarkApi(path);

    if (isBookmarked) {
      $(this).find(notBookmarkedIconSelector).addClass(inactiveClass);
      $(this).find(bookmarkedIconSelector).removeClass(inactiveClass);
    } else {
      $(this).find(notBookmarkedIconSelector).removeClass(inactiveClass);
      $(this).find(bookmarkedIconSelector).addClass(inactiveClass);
    }
  };
}

async function callBookmarkApi(path) {
  const tokenElement = document.querySelector("[name=csrf-token]");
  const token = tokenElement ? tokenElement.content : null;

  const response = await fetch(path, {
    credentials: "same-origin",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": token,
    },
  });

  if (response.status >= 300) {
    throw new Error("Cannot bookmark");
  } else {
    const data = await response.json();
    return data.bookmarked;
  }
}

export function modernCollapsable(clickSelector) {
  runAfterPageLoad(() => {
    $(clickSelector).on("keydown", (event) => {
      const elem = $(event.target);
      const collapsible = $(elem.data("target"));

      // enter or space
      if (event.which === 13 || event.which === 32) {
        collapsible.collapse("toggle");
      }
    });
  });
}

export function openModernBookModal(clickSelector) {
  runAfterPageLoad(() => {
    $(clickSelector).on("keydown", (event) => {
      if (event.which === 13 || event.which === 32) {
        $(event.target).find(".modern-cta-button-wrapper").click();
      }
    });
  });
}

export function flashMessages() {
  runAfterPageLoad(() => {
    $("#messages .flash-positive").delay(5000).fadeOut();

    $("#messages").on("click", ".close-button", function closeFlashMessage() {
      const button = $(this);
      const flash = button.closest(".flash");
      flash.hide();
    });
  });
}

export function modernButtonDisable(clickSelector, affectedElement) {
  runAfterPageLoad(() => {
    $(clickSelector).on("submit", () => {
      $(affectedElement).attr("disabled", true);
    });
  });
}
